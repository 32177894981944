// This is our base Sentry config that is used on all runtimes: client, edge and server.

import type { BrowserOptions, EdgeOptions, NodeOptions } from '@sentry/nextjs';
import type { Options } from '@sentry/types';

const sentryConfig: Options & BrowserOptions & EdgeOptions & NodeOptions = {
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment:
    'platform.carbonfact.com' || // Placed in-line at build time, see package.json prebuild:prod script
    process.env.NODE_ENV,
  release:
    process.env.NODE_ENV === 'production'
      ? `${'9448a5dd73b771d6493f7e46fae557a351335d17'}` // Replaced in-line at build time, see package.json prebuild:prod script
      : `platform-${process.env.NODE_ENV}-${process.env.USER}`,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.6 : 1.0,

  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0,

  tracePropagationTargets: [
    /^https:\/\/(stable|live|demo)\.api\.carbonfact\.com\//,
  ],
};

export default sentryConfig;
